import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 3264.000000 3264.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">

<path d="M15830 32060 c-179 -4 -374 -12 -435 -18 -60 -6 -182 -16 -270 -22
-196 -13 -346 -27 -490 -46 -125 -16 -226 -28 -370 -45 -107 -12 -351 -46
-425 -59 -25 -5 -72 -13 -105 -18 -33 -6 -100 -18 -150 -27 -49 -9 -115 -21
-145 -26 -125 -21 -170 -29 -260 -49 -52 -12 -111 -25 -130 -30 -69 -17 -266
-61 -310 -71 -82 -17 -264 -59 -300 -69 -194 -56 -476 -134 -570 -158 -70 -18
-401 -124 -445 -142 -11 -4 -58 -21 -105 -36 -157 -50 -295 -96 -315 -104 -11
-4 -51 -20 -90 -35 -38 -15 -79 -31 -90 -35 -11 -4 -117 -45 -235 -90 -233
-88 -416 -164 -628 -261 -34 -16 -64 -29 -67 -29 -2 0 -37 -15 -77 -34 -40
-19 -98 -45 -128 -57 -116 -49 -779 -388 -945 -484 -163 -94 -168 -96 -230
-130 -33 -17 -79 -44 -102 -58 -24 -15 -45 -27 -47 -27 -10 0 -193 -112 -546
-335 -19 -12 -84 -53 -145 -90 -60 -38 -135 -87 -165 -110 -30 -22 -81 -58
-113 -80 -249 -170 -512 -361 -657 -478 -8 -7 -33 -26 -55 -42 -22 -16 -42
-32 -45 -35 -11 -11 -157 -127 -195 -154 -22 -16 -42 -32 -45 -35 -3 -3 -27
-24 -55 -46 -48 -37 -79 -64 -275 -234 -47 -41 -96 -83 -110 -95 -97 -81 -157
-133 -170 -146 -8 -8 -80 -76 -160 -150 -140 -130 -376 -356 -449 -431 -153
-154 -538 -561 -582 -613 -13 -16 -46 -55 -74 -86 -27 -31 -119 -136 -204
-234 -139 -160 -196 -229 -322 -391 -56 -72 -178 -227 -192 -245 -86 -107
-156 -200 -165 -217 -7 -12 -18 -28 -25 -35 -8 -7 -26 -31 -40 -53 -15 -22
-37 -54 -49 -72 -224 -315 -442 -639 -553 -821 -5 -9 -46 -72 -89 -141 -73
-115 -101 -163 -174 -291 -14 -25 -65 -114 -114 -199 -48 -84 -88 -155 -88
-156 0 -2 -19 -36 -43 -77 -46 -78 -420 -822 -451 -898 -10 -25 -31 -72 -46
-105 -15 -33 -44 -98 -65 -145 -21 -47 -49 -110 -62 -140 -14 -30 -31 -68 -38
-85 -7 -16 -24 -55 -38 -86 -14 -30 -32 -75 -40 -100 -16 -45 -51 -139 -66
-174 -9 -22 -40 -100 -91 -235 -18 -47 -38 -98 -45 -115 -7 -16 -18 -46 -25
-65 -7 -19 -16 -44 -20 -55 -5 -11 -47 -137 -94 -280 -47 -143 -100 -300 -116
-350 -17 -49 -39 -121 -50 -160 -10 -38 -25 -95 -34 -125 -9 -30 -25 -89 -37
-130 -11 -41 -27 -100 -36 -130 -8 -30 -19 -68 -23 -85 -5 -16 -16 -57 -25
-90 -26 -94 -61 -234 -75 -300 -11 -53 -43 -195 -70 -310 -8 -36 -24 -105 -49
-220 -18 -80 -44 -222 -91 -507 -6 -35 -15 -85 -20 -113 -33 -191 -41 -240
-50 -317 -6 -49 -15 -123 -20 -165 -23 -180 -41 -323 -50 -393 -9 -76 -17
-168 -45 -535 -31 -415 -36 -562 -36 -1106 0 -550 7 -777 36 -1154 8 -104 20
-248 25 -320 6 -71 15 -159 20 -195 5 -36 16 -128 25 -205 9 -77 18 -153 21
-170 3 -16 9 -68 15 -115 14 -128 26 -213 48 -340 11 -63 25 -146 32 -185 6
-38 15 -90 19 -115 5 -25 16 -88 25 -140 21 -125 32 -181 76 -370 6 -25 14
-63 19 -85 18 -80 41 -181 51 -225 11 -46 19 -83 40 -180 7 -30 23 -93 36
-140 13 -47 28 -101 33 -120 5 -19 19 -69 31 -110 11 -41 24 -91 29 -110 8
-33 56 -208 82 -295 48 -162 290 -878 328 -970 16 -38 23 -57 50 -130 13 -33
28 -73 35 -90 13 -32 43 -110 96 -245 18 -47 50 -122 71 -168 21 -46 38 -85
38 -87 0 -3 14 -35 31 -72 52 -115 79 -175 172 -388 50 -114 411 -824 437
-860 4 -5 21 -35 37 -65 16 -30 69 -125 118 -210 48 -85 97 -171 108 -190 10
-19 28 -51 40 -70 36 -61 431 -685 437 -690 3 -3 91 -126 195 -275 162 -232
318 -448 335 -465 3 -3 18 -23 35 -45 16 -22 32 -42 35 -45 4 -3 25 -30 48
-60 23 -30 63 -82 89 -116 26 -33 62 -78 78 -100 57 -74 117 -149 149 -184 48
-54 325 -376 382 -444 116 -140 620 -659 839 -865 55 -52 141 -133 190 -180
114 -109 192 -180 280 -256 38 -33 90 -78 115 -100 25 -22 75 -65 110 -95 35
-30 100 -87 145 -126 106 -92 678 -538 829 -645 65 -46 135 -96 155 -110 231
-164 384 -271 421 -293 25 -15 72 -45 105 -66 33 -22 67 -43 75 -47 8 -3 36
-21 61 -40 26 -18 49 -33 52 -33 3 0 68 -40 144 -88 76 -49 158 -100 183 -114
25 -14 61 -34 80 -45 190 -108 343 -193 347 -193 3 0 17 -8 31 -19 15 -10 50
-31 77 -46 85 -47 776 -394 855 -430 41 -18 104 -46 140 -63 36 -16 103 -46
150 -67 47 -21 105 -47 130 -57 25 -11 87 -40 139 -64 51 -24 97 -44 102 -44
5 0 17 -4 27 -9 9 -5 44 -19 77 -32 33 -12 74 -28 90 -34 17 -7 127 -50 245
-95 118 -45 226 -86 240 -92 35 -14 559 -189 595 -198 26 -7 118 -38 180 -60
11 -4 52 -15 90 -25 39 -10 90 -24 115 -31 25 -7 88 -24 140 -39 52 -14 111
-31 130 -36 19 -6 51 -14 70 -19 19 -5 78 -20 130 -35 52 -14 118 -31 145 -36
114 -23 132 -26 175 -38 63 -18 106 -28 175 -42 33 -6 107 -23 165 -36 137
-31 177 -40 285 -58 50 -8 113 -20 140 -25 28 -5 68 -12 90 -16 22 -3 63 -10
90 -14 28 -5 88 -15 135 -24 47 -9 119 -21 160 -26 205 -24 254 -30 365 -45
315 -43 530 -64 975 -96 151 -11 790 -30 1000 -30 208 0 858 19 1010 30 448
32 663 53 975 96 66 9 158 20 205 26 158 17 235 28 315 44 44 9 109 21 145 26
36 6 88 14 115 19 76 12 335 59 380 69 22 4 83 18 135 30 52 12 114 26 138 31
394 89 611 144 962 244 25 7 90 25 145 40 98 27 155 44 205 64 14 5 140 47
280 92 140 46 264 86 275 90 11 4 38 15 60 23 22 9 137 52 255 97 221 84 230
87 290 113 19 8 53 21 75 28 22 7 44 17 50 21 5 4 14 8 21 8 6 0 54 20 105 44
95 44 114 53 269 121 47 20 112 50 145 65 33 15 85 38 115 51 57 25 756 375
850 426 30 16 74 41 97 56 24 15 45 27 47 27 2 0 73 39 158 86 84 48 178 100
208 117 30 17 129 77 218 134 90 56 165 103 167 103 1 0 32 19 68 43 36 23
112 71 169 106 83 52 295 199 528 366 15 11 72 51 127 89 54 38 187 139 296
225 110 86 214 167 232 181 77 59 115 89 195 154 47 38 103 86 125 105 22 20
69 61 105 91 79 68 391 340 406 354 6 6 96 92 200 191 316 299 764 758 899
920 32 39 62 72 65 75 32 25 436 505 550 655 38 50 129 167 202 261 73 94 148
193 167 220 19 27 57 81 84 119 27 39 61 86 75 106 167 228 647 968 726 1119
16 30 37 67 48 82 10 14 18 29 18 32 0 3 13 27 29 54 16 27 44 76 62 108 63
114 72 130 109 194 45 79 396 773 429 850 29 69 43 101 74 170 13 30 41 93 62
140 21 47 49 110 62 140 14 30 31 69 38 85 7 17 25 56 39 87 14 31 26 60 26
65 0 8 62 170 79 208 5 11 42 106 81 210 39 105 75 199 80 210 17 39 58 164
140 415 45 140 86 264 90 275 20 49 42 123 60 195 4 19 22 85 39 145 17 61 36
128 42 150 6 22 17 60 24 85 7 25 18 63 24 85 22 81 58 222 70 275 7 30 16 73
21 95 5 22 16 72 25 110 9 39 20 88 25 110 5 22 13 60 19 85 50 222 60 269 65
300 2 19 12 76 21 125 30 167 36 200 46 260 11 70 22 135 44 260 9 50 20 124
25 165 22 194 35 302 70 575 5 39 14 131 19 205 6 74 18 225 26 335 38 493 51
1018 36 1503 -6 218 -16 453 -21 522 -6 69 -15 190 -21 270 -22 316 -29 398
-38 470 -5 41 -17 131 -26 200 -9 69 -21 166 -26 215 -6 50 -14 124 -19 165
-4 41 -16 116 -25 165 -18 99 -33 182 -40 235 -3 19 -10 58 -15 85 -5 28 -17
91 -25 140 -32 185 -37 212 -51 275 -8 36 -20 88 -25 115 -6 28 -15 66 -19 85
-5 19 -14 62 -20 95 -7 33 -18 80 -25 105 -7 25 -18 72 -25 105 -14 72 -21
103 -50 215 -12 47 -25 101 -30 120 -5 19 -13 51 -18 70 -6 19 -20 69 -31 110
-24 85 -31 109 -66 235 -14 50 -30 108 -36 130 -14 50 -59 193 -69 220 -5 11
-47 137 -94 280 -92 281 -155 461 -185 530 -5 11 -20 49 -34 85 -23 61 -39
101 -131 338 -20 51 -46 114 -57 140 -12 26 -27 61 -34 77 -7 17 -34 77 -59
135 -26 58 -51 114 -55 125 -5 11 -22 49 -39 85 -16 36 -44 99 -62 140 -32 74
-263 540 -365 735 -53 102 -64 121 -137 248 -22 40 -77 135 -121 212 -44 77
-90 158 -102 180 -13 22 -41 69 -64 105 -22 36 -52 85 -67 110 -15 25 -50 81
-79 125 -28 44 -87 136 -130 205 -73 117 -184 284 -212 320 -7 8 -97 135 -200
282 -196 278 -666 888 -723 938 -3 3 -48 55 -100 115 -52 61 -107 124 -121
140 -15 17 -62 71 -104 120 -43 50 -109 124 -149 165 -39 41 -147 156 -241
255 -201 212 -240 251 -501 500 -293 280 -329 313 -640 580 -70 61 -132 115
-138 120 -7 6 -38 33 -71 60 -55 47 -82 68 -170 135 -18 14 -124 97 -235 185
-111 88 -254 197 -318 243 -65 46 -126 90 -137 98 -84 59 -199 141 -244 172
-29 20 -66 46 -81 57 -24 18 -165 110 -216 142 -42 26 -150 94 -168 106 -11 6
-98 62 -195 122 -175 110 -220 137 -316 191 -27 15 -66 36 -85 47 -35 20 -95
54 -157 88 -17 10 -50 29 -72 43 -22 15 -43 26 -45 26 -3 0 -29 14 -58 32 -95
56 -790 411 -893 455 -56 24 -244 107 -290 128 -16 7 -60 27 -98 44 -37 17
-70 31 -72 31 -2 0 -30 12 -62 26 -32 15 -82 37 -111 50 -29 13 -57 24 -62 24
-6 0 -18 4 -28 9 -9 6 -102 42 -207 81 -104 40 -199 75 -210 80 -11 4 -51 20
-90 35 -38 15 -79 31 -90 36 -19 7 -126 43 -320 105 -44 14 -89 30 -100 34
-44 18 -375 124 -445 142 -81 21 -106 28 -190 53 -30 9 -71 20 -90 25 -19 5
-51 13 -70 19 -179 51 -386 104 -455 117 -38 7 -81 16 -220 49 -60 14 -130 29
-155 35 -25 5 -83 18 -130 29 -47 11 -103 22 -125 26 -22 3 -60 10 -85 15 -45
9 -175 32 -285 51 -33 5 -82 14 -110 19 -172 32 -217 38 -495 71 -125 15 -220
26 -355 43 -256 32 -292 36 -352 36 -34 0 -106 4 -160 10 -401 40 -1052 55
-1733 40z m-2010 -7284 c2544 -1 4726 2 4850 5 124 3 234 3 245 -2 11 -4 32
-10 48 -14 15 -3 36 -14 47 -25 25 -25 18 -74 -22 -150 -15 -30 -33 -82 -38
-115 -20 -113 -42 -200 -61 -241 -10 -23 -23 -61 -28 -85 -5 -24 -14 -65 -20
-92 -6 -26 -11 -57 -11 -68 0 -11 -16 -57 -35 -102 -23 -55 -39 -116 -49 -184
-8 -56 -19 -110 -24 -120 -19 -37 -52 -140 -67 -213 -8 -41 -21 -100 -27 -130
-6 -30 -22 -76 -34 -102 -13 -25 -33 -97 -44 -160 -22 -117 -34 -164 -68 -258
-12 -30 -30 -104 -42 -165 -12 -60 -33 -134 -46 -164 -23 -50 -32 -91 -65
-266 -7 -33 -23 -79 -36 -103 -16 -28 -30 -77 -39 -138 -8 -53 -23 -121 -35
-152 -39 -109 -58 -177 -74 -267 -19 -105 -29 -144 -57 -205 -11 -25 -24 -74
-29 -110 -11 -83 -18 -107 -72 -264 -28 -78 -55 -138 -69 -153 l-25 -24 -2847
-2 -2848 -2 -17 -21 c-10 -12 -25 -62 -35 -110 -12 -61 -24 -97 -40 -114 -30
-33 -43 -71 -51 -145 -10 -97 -25 -165 -56 -242 -16 -39 -36 -109 -45 -155
-16 -86 -29 -139 -44 -173 -31 -69 -42 -111 -61 -219 -14 -82 -30 -139 -50
-178 -29 -57 -29 -60 -13 -87 9 -16 33 -36 54 -45 34 -15 260 -16 2715 -16
1982 0 2700 3 2765 12 103 13 141 3 171 -46 24 -40 24 -53 -5 -161 -13 -49
-30 -115 -36 -145 -7 -30 -26 -94 -41 -143 -16 -48 -40 -141 -54 -207 -13 -66
-31 -133 -40 -149 -8 -17 -26 -76 -40 -133 -14 -57 -34 -134 -45 -173 -25 -90
-60 -229 -71 -281 -4 -23 -20 -74 -34 -115 -14 -41 -39 -135 -54 -210 -16 -74
-38 -153 -49 -175 -11 -21 -25 -73 -31 -114 -12 -79 -57 -248 -71 -265 -9 -11
-36 -121 -43 -175 -10 -75 -37 -170 -61 -217 -14 -28 -28 -78 -32 -114 -4 -35
-26 -125 -49 -199 -23 -74 -48 -166 -55 -205 -7 -38 -24 -101 -37 -140 -34
-97 -58 -187 -71 -272 -7 -39 -23 -97 -37 -128 -13 -31 -31 -90 -40 -132 -17
-83 -27 -106 -59 -141 -51 -57 162 -53 -2905 -52 -2465 0 -2830 -2 -2840 -14
-7 -8 -19 -44 -26 -81 -9 -41 -26 -82 -45 -109 -24 -34 -34 -60 -41 -115 -10
-87 -23 -136 -55 -216 -13 -33 -35 -119 -48 -191 -13 -72 -38 -167 -56 -210
-19 -49 -35 -111 -41 -163 -6 -49 -21 -113 -38 -155 -31 -77 -44 -123 -62
-231 -14 -84 -29 -136 -64 -230 -15 -38 -32 -109 -40 -156 -8 -55 -24 -108
-44 -148 -23 -44 -36 -91 -47 -164 -16 -114 -28 -155 -56 -203 -18 -31 -58
-218 -58 -274 0 -15 -16 -57 -35 -93 -27 -52 -38 -89 -51 -174 -11 -75 -26
-131 -49 -183 -21 -48 -39 -111 -49 -175 -22 -145 -32 -187 -60 -252 -14 -33
-35 -111 -47 -174 -12 -63 -33 -136 -46 -163 -15 -29 -33 -96 -44 -160 -11
-61 -31 -140 -45 -175 -14 -34 -38 -122 -54 -195 -15 -72 -40 -157 -54 -190
-14 -32 -35 -112 -47 -180 -11 -66 -31 -148 -45 -181 -14 -33 -38 -122 -54
-197 -16 -76 -38 -155 -48 -175 -10 -21 -24 -65 -31 -97 -10 -44 -22 -69 -50
-98 l-36 -38 -1815 1 c-2459 1 -2268 -1 -2306 26 -45 32 -52 68 -19 101 14 14
28 37 30 51 27 137 50 222 77 281 31 69 46 124 58 223 7 55 21 112 36 142 13
28 30 77 38 110 29 120 36 156 36 173 0 9 13 49 29 89 16 39 33 93 36 120 4
26 16 81 26 120 10 40 19 82 19 93 0 10 10 43 23 72 13 29 28 78 35 108 6 30
18 87 26 127 8 39 24 91 35 115 22 48 51 162 51 198 0 13 4 37 10 54 5 17 25
78 44 136 18 58 39 137 46 175 7 39 28 111 46 160 32 87 45 144 59 249 4 27
19 77 34 110 15 34 35 106 45 161 21 115 39 175 62 210 19 29 32 77 44 165 21
152 27 178 48 207 12 17 26 53 31 79 6 27 17 87 26 134 10 47 30 114 47 150
16 36 34 90 39 120 6 30 14 78 19 105 19 109 30 146 55 184 21 31 28 58 35
132 10 104 30 182 54 208 8 9 22 55 31 101 29 157 38 195 71 287 18 50 38 131
44 180 8 62 22 110 46 161 24 53 39 108 54 200 12 70 28 140 36 156 8 16 14
35 14 43 0 7 4 21 9 31 18 37 41 121 41 153 0 45 22 124 47 174 26 50 39 98
54 200 6 44 25 108 41 143 16 35 33 89 39 120 32 183 48 250 65 277 15 23 42
125 54 200 9 56 22 101 40 135 18 34 50 156 50 192 0 18 9 62 20 98 11 36 20
73 20 83 0 11 6 33 14 50 8 18 27 86 42 152 15 66 35 138 46 160 21 46 57 185
65 259 4 28 15 67 26 86 11 19 24 53 28 75 4 22 12 58 18 80 5 22 18 76 29
120 11 44 31 113 46 152 14 40 26 83 26 96 0 13 4 37 9 53 5 16 15 56 22 89 7
33 23 83 36 110 12 28 23 65 23 82 0 42 16 108 52 213 28 80 35 108 57 213 29
138 46 199 71 252 17 38 30 84 33 125 5 62 27 143 61 222 9 21 21 66 26 100 6
35 13 79 16 98 3 19 9 44 14 55 8 19 53 165 70 230 5 17 11 54 14 84 3 29 19
83 36 118 16 36 37 101 45 146 31 164 38 193 60 242 22 49 33 91 55 210 13 69
35 142 61 200 10 22 26 92 37 157 14 77 30 138 51 181 17 36 31 75 31 88 0 21
13 90 39 209 4 20 17 56 28 80 24 52 40 107 48 170 12 90 24 137 55 210 17 42
35 107 41 150 9 74 28 146 54 205 26 60 43 121 50 180 8 74 19 114 51 184 15
31 33 106 45 180 15 88 28 136 44 161 14 22 29 71 40 135 23 134 43 212 64
254 22 45 47 145 66 272 3 20 19 63 34 95 21 43 36 98 52 199 13 77 27 146 33
154 6 8 31 27 56 43 37 24 51 28 80 22 19 -4 2116 -8 4660 -8z m7634 -37 c16
-5 62 -9 102 -9 40 0 76 -4 79 -10 3 -5 28 -10 55 -10 26 0 50 -4 52 -9 2 -5
41 -11 88 -14 47 -3 98 -10 113 -16 16 -6 55 -11 87 -11 32 0 62 -4 65 -10 3
-5 35 -10 71 -10 36 0 64 -4 64 -10 0 -5 19 -10 43 -10 24 0 47 -4 53 -9 11
-12 85 -32 142 -38 23 -3 42 -9 42 -14 0 -5 24 -9 52 -9 46 0 152 -31 210 -61
10 -5 24 -9 32 -9 7 0 27 -7 42 -15 16 -8 46 -15 66 -15 20 0 47 -4 60 -9 13
-5 43 -15 68 -22 25 -7 50 -16 55 -19 6 -4 51 -20 100 -35 50 -16 106 -39 125
-52 19 -13 47 -23 62 -23 15 0 30 -4 33 -10 3 -5 17 -10 31 -10 13 0 32 -4 42
-8 9 -5 42 -21 72 -35 30 -15 61 -27 68 -27 6 0 12 -4 12 -10 0 -5 14 -10 30
-10 17 0 86 -27 154 -60 68 -33 129 -60 135 -60 6 0 11 -4 11 -10 0 -5 8 -10
18 -10 9 0 30 -11 46 -25 16 -14 39 -25 52 -25 13 0 26 -4 29 -10 3 -5 15 -10
26 -10 10 0 19 -4 19 -10 0 -5 6 -10 14 -10 8 0 28 -11 44 -25 17 -14 34 -25
39 -25 15 0 128 -57 144 -72 29 -28 64 -48 86 -48 12 0 38 -16 59 -35 20 -19
45 -35 55 -35 10 0 21 -4 24 -10 3 -5 17 -10 30 -10 16 0 40 -16 67 -45 23
-25 50 -45 60 -45 23 0 84 -36 129 -76 20 -18 47 -35 60 -39 13 -4 40 -22 59
-40 19 -18 50 -38 68 -46 17 -8 32 -17 32 -21 0 -5 6 -8 13 -8 7 0 26 -14 43
-31 16 -17 48 -42 71 -55 23 -14 49 -36 58 -50 9 -14 36 -34 59 -45 23 -10 47
-29 54 -43 6 -14 34 -35 67 -52 31 -15 61 -37 67 -49 5 -11 17 -25 27 -30 42
-23 101 -66 101 -74 0 -5 24 -27 52 -48 29 -21 89 -74 133 -119 44 -44 92 -88
108 -98 15 -9 27 -22 27 -30 0 -12 53 -66 66 -66 8 0 139 -131 145 -145 2 -6
23 -25 47 -43 23 -19 42 -39 42 -46 0 -7 41 -53 90 -103 50 -50 90 -97 90
-104 0 -8 20 -31 44 -52 24 -20 60 -62 80 -92 20 -30 43 -58 50 -62 7 -4 21
-23 30 -41 9 -18 35 -49 56 -70 22 -20 40 -41 40 -46 0 -7 74 -106 166 -221 8
-11 26 -40 39 -65 13 -25 42 -63 64 -85 23 -21 41 -45 41 -52 0 -16 48 -103
71 -129 11 -11 19 -25 19 -30 0 -5 18 -34 40 -63 40 -53 62 -94 75 -140 4 -13
18 -36 31 -51 28 -30 64 -97 64 -117 0 -7 15 -30 34 -50 18 -21 39 -56 45 -78
7 -22 19 -54 27 -72 8 -17 14 -36 14 -41 0 -6 8 -13 18 -16 16 -6 42 -48 42
-71 0 -10 64 -140 80 -160 4 -5 17 -39 29 -75 13 -35 36 -84 52 -108 16 -23
29 -54 29 -67 0 -13 5 -27 10 -30 6 -3 10 -20 10 -37 0 -17 19 -66 44 -112 24
-45 49 -107 55 -141 7 -33 14 -70 17 -82 2 -12 14 -31 25 -42 21 -20 37 -67
46 -136 3 -22 9 -44 14 -50 5 -5 9 -19 9 -32 0 -12 11 -46 24 -75 34 -75 44
-107 50 -158 7 -63 24 -136 35 -157 5 -10 15 -38 21 -63 7 -25 16 -54 20 -65
11 -26 29 -123 40 -210 13 -103 26 -167 46 -221 20 -57 30 -123 44 -289 20
-248 39 -444 56 -575 20 -164 24 -656 7 -795 -7 -49 -17 -151 -23 -225 -6 -74
-16 -175 -22 -225 -5 -49 -14 -142 -19 -205 -12 -165 -23 -239 -43 -284 -17
-42 -32 -120 -47 -246 -14 -126 -30 -202 -54 -255 -17 -38 -35 -101 -39 -140
-4 -36 -43 -179 -55 -203 -25 -49 -41 -95 -41 -117 0 -13 -4 -27 -10 -30 -5
-3 -10 -25 -10 -48 0 -51 -19 -109 -46 -139 -20 -23 -39 -71 -47 -118 -10 -56
-30 -115 -56 -168 -16 -32 -41 -96 -56 -144 -15 -48 -36 -96 -47 -107 -17 -20
-39 -78 -53 -145 -3 -16 -19 -42 -36 -58 -16 -16 -29 -36 -29 -43 0 -8 -4 -17
-10 -20 -5 -3 -10 -15 -10 -26 0 -24 -35 -95 -52 -106 -15 -9 -48 -77 -48 -97
0 -19 -29 -76 -60 -116 -15 -19 -33 -51 -40 -70 -7 -19 -17 -39 -22 -44 -4 -6
-8 -16 -8 -24 0 -8 -16 -32 -35 -55 -19 -22 -35 -49 -35 -60 0 -10 -15 -34
-32 -52 -18 -17 -46 -61 -63 -96 -16 -35 -44 -80 -62 -99 -18 -20 -33 -44 -33
-54 0 -10 -18 -36 -40 -59 -23 -23 -49 -59 -59 -82 -10 -22 -39 -67 -65 -99
-25 -33 -46 -64 -46 -69 0 -5 -20 -32 -45 -59 -25 -28 -45 -56 -45 -62 0 -7
-12 -23 -26 -37 -14 -13 -40 -44 -57 -69 -17 -25 -52 -68 -79 -97 -26 -28 -48
-58 -48 -66 0 -8 -18 -26 -41 -41 -23 -15 -45 -40 -51 -57 -6 -16 -23 -36 -38
-44 -15 -8 -35 -30 -44 -50 -9 -19 -30 -42 -46 -50 -17 -9 -39 -33 -50 -55
-11 -21 -33 -47 -50 -57 -16 -9 -30 -23 -30 -30 0 -6 -20 -29 -45 -49 -24 -21
-52 -53 -60 -71 -9 -18 -28 -39 -43 -47 -31 -16 -172 -151 -172 -165 0 -5 -14
-14 -30 -21 -17 -7 -39 -24 -50 -37 -10 -13 -35 -35 -55 -48 -19 -14 -53 -42
-73 -62 -21 -21 -58 -55 -83 -76 -104 -88 -186 -154 -219 -177 -19 -13 -44
-34 -55 -46 -11 -12 -34 -30 -51 -40 -17 -11 -46 -32 -65 -47 -60 -50 -193
-140 -227 -154 -18 -7 -48 -29 -67 -48 -18 -19 -40 -35 -48 -35 -8 0 -47 -25
-88 -55 -41 -31 -86 -58 -102 -62 -15 -3 -39 -18 -55 -33 -15 -15 -61 -42
-102 -59 -41 -17 -80 -39 -87 -50 -6 -10 -42 -30 -80 -45 -37 -15 -84 -40
-103 -55 -19 -15 -57 -36 -85 -46 -27 -10 -65 -29 -84 -43 -18 -14 -66 -37
-105 -52 -39 -15 -89 -39 -110 -53 -21 -15 -75 -38 -119 -52 -44 -14 -91 -34
-106 -44 -25 -18 -178 -71 -205 -71 -7 0 -21 -9 -31 -20 -19 -21 -75 -40 -176
-60 -31 -6 -79 -26 -108 -44 -33 -22 -70 -36 -104 -40 -71 -9 -186 -42 -250
-71 -30 -14 -99 -31 -162 -41 -65 -9 -145 -29 -195 -49 -70 -26 -113 -35 -249
-49 -112 -11 -187 -24 -235 -41 -83 -29 -178 -40 -451 -55 -110 -5 -276 -15
-370 -20 -249 -14 -122 -14 -5320 -12 -2640 1 -4827 -1 -4860 -4 -81 -8 -138
8 -166 47 -21 29 -21 35 -9 83 7 28 22 71 35 96 21 44 39 102 56 185 15 80 50
187 75 237 8 15 14 46 14 70 0 25 19 100 46 178 26 74 50 162 55 195 14 104
20 128 55 222 19 51 34 103 34 116 0 12 4 27 9 32 5 6 11 39 13 75 3 47 13 84
36 131 18 37 32 75 32 84 0 10 4 34 10 54 5 20 16 74 25 119 9 46 20 91 25
100 13 26 22 53 36 107 7 28 18 68 24 90 7 22 15 65 19 95 4 30 21 84 39 120
17 36 33 83 36 105 16 124 47 238 76 280 4 6 10 33 13 60 9 79 46 226 68 267
22 41 38 106 46 191 3 31 9 60 14 63 5 3 9 16 9 30 0 13 11 45 24 71 32 63 46
111 46 158 0 38 57 251 84 316 8 19 24 42 35 51 20 17 271 18 4693 18 2585 0
4676 4 4682 9 6 4 40 9 76 10 78 2 91 4 108 15 11 7 73 18 110 19 6 0 12 4 12
9 0 4 7 8 16 8 8 0 12 5 9 10 -4 6 8 10 27 11 55 1 199 39 266 70 55 25 186
69 207 69 9 0 14 4 11 9 -3 5 16 17 44 27 27 10 47 21 44 26 -3 4 6 8 20 8 14
0 27 4 30 9 3 5 20 12 38 15 18 3 47 19 63 35 17 15 49 34 70 41 60 19 97 40
117 66 10 12 34 28 53 34 19 6 35 16 35 21 0 5 6 9 14 9 7 0 28 16 47 35 19
19 44 35 55 35 13 0 48 25 84 60 34 33 70 60 80 60 18 0 83 53 126 102 7 7 35
33 63 58 28 25 59 53 69 62 9 10 61 62 115 116 53 54 97 103 97 108 0 14 53
64 67 64 6 0 16 12 21 28 6 15 24 41 41 59 17 18 47 60 67 94 20 34 51 74 68
89 17 16 37 45 45 66 7 22 26 53 41 69 15 17 40 55 55 85 15 30 38 67 50 82
12 15 37 62 55 105 18 43 43 90 55 105 13 15 33 60 44 100 12 40 29 87 37 103
34 65 42 81 54 115 7 19 15 52 17 73 3 20 9 40 14 43 5 3 9 14 9 25 0 11 10
39 21 62 29 57 39 102 65 282 4 28 16 64 28 80 31 45 39 173 39 675 0 428 -7
553 -31 598 -28 52 -32 64 -32 101 0 22 -4 43 -10 46 -5 3 -10 35 -10 69 0 35
-7 83 -15 106 -8 23 -15 52 -15 63 0 11 -11 35 -26 54 -14 18 -23 37 -20 41 3
5 -2 21 -10 36 -7 16 -14 41 -14 57 0 16 -4 29 -10 29 -5 0 -10 20 -10 45 0
35 -4 45 -16 45 -10 0 -14 6 -10 15 3 8 -6 27 -19 41 -14 15 -25 39 -25 55 0
15 -4 31 -10 34 -5 3 -10 17 -10 31 0 14 -7 27 -15 30 -8 4 -15 17 -15 30 0
15 -6 24 -15 24 -8 0 -17 12 -21 28 -6 30 -10 40 -22 62 -24 42 -32 62 -32 80
0 11 -5 20 -11 20 -21 0 -59 57 -59 88 0 11 -11 31 -25 46 -14 15 -25 30 -25
34 0 4 -9 13 -20 20 -11 7 -20 21 -20 31 0 11 -4 22 -9 25 -4 3 -11 18 -14 33
-4 19 -17 34 -41 47 -21 11 -36 27 -36 37 0 10 -4 21 -10 24 -5 3 -10 14 -10
24 0 10 -11 23 -24 31 -14 7 -42 34 -64 61 l-39 48 -30 -27 c-33 -29 -53 -76
-53 -124 0 -15 -9 -47 -19 -71 -29 -64 -71 -196 -71 -226 -1 -42 -26 -136 -48
-178 -11 -21 -26 -75 -32 -120 -10 -76 -30 -156 -49 -193 -14 -29 -41 -126
-56 -205 -9 -44 -21 -98 -26 -120 -6 -22 -13 -52 -16 -67 -3 -16 -9 -28 -13
-28 -12 0 -38 -98 -44 -167 -3 -34 -14 -86 -25 -115 -11 -29 -28 -78 -39 -108
-11 -30 -25 -86 -32 -125 -6 -38 -17 -90 -24 -115 -8 -25 -19 -65 -26 -90 -7
-25 -18 -61 -26 -80 -7 -19 -18 -64 -24 -100 -14 -84 -27 -131 -55 -205 -27
-72 -45 -145 -45 -182 0 -34 -15 -84 -45 -148 -21 -45 -52 -175 -70 -295 -4
-25 -15 -63 -25 -85 -30 -68 -48 -127 -60 -205 -14 -94 -29 -146 -53 -188 -11
-18 -26 -70 -33 -115 -18 -112 -36 -190 -45 -202 -16 -21 -38 -103 -60 -215
-11 -63 -27 -126 -34 -140 -15 -29 -60 -73 -92 -89 -15 -8 -572 -11 -2028 -11
l-2007 0 -41 28 c-34 22 -43 34 -45 62 -7 63 41 223 68 232 6 2 17 37 25 78
30 160 38 186 66 240 16 30 29 67 29 82 0 15 4 29 9 32 4 3 11 27 14 53 4 27
14 77 23 113 8 36 18 77 21 93 3 15 9 27 13 27 6 0 18 34 51 146 5 17 9 46 9
65 0 41 17 92 48 140 16 26 22 52 22 91 0 30 4 58 9 64 5 5 12 28 14 50 3 23
10 51 16 62 6 11 11 32 11 45 0 13 8 31 18 41 12 10 25 48 36 104 9 48 21 106
26 130 6 23 10 53 10 67 0 13 8 39 18 57 34 63 52 124 52 180 0 31 5 60 10 63
6 3 10 19 10 35 0 27 44 144 62 164 4 6 8 31 8 56 0 25 4 50 9 56 5 5 13 34
19 64 14 76 43 184 52 190 15 11 40 106 40 150 0 44 10 89 30 135 24 53 54
156 61 205 10 76 18 110 35 147 8 17 14 38 14 46 0 9 5 19 10 22 6 3 10 17 10
31 0 13 4 32 9 42 13 25 32 108 41 178 4 34 14 76 23 95 34 78 67 194 67 238
0 30 5 45 41 128 10 23 23 66 28 95 6 29 15 73 21 98 5 25 14 68 19 97 5 28
19 69 30 92 12 22 21 53 21 69 0 15 4 32 9 37 5 6 14 35 19 65 20 100 23 115
34 153 6 20 14 37 19 37 4 0 9 15 12 33 3 17 13 64 22 102 9 39 18 89 22 113
3 23 10 45 14 48 5 3 9 18 9 33 0 15 4 31 9 36 5 6 17 42 26 80 10 39 21 78
26 87 5 10 9 35 9 56 0 21 9 57 20 80 11 22 20 47 20 54 0 8 6 22 14 33 8 11
16 43 19 70 13 117 39 220 60 241 4 4 7 19 7 34 0 14 4 30 9 35 5 6 13 35 19
65 6 30 16 73 22 95 7 22 14 56 17 75 3 19 9 40 13 45 11 16 32 76 40 120 4
22 17 74 29 115 11 41 21 84 21 95 0 12 9 38 20 60 11 22 20 51 20 65 0 15 3
30 7 34 4 4 13 39 19 77 11 63 35 154 54 204 22 60 32 93 36 115 13 71 28 132
36 136 4 3 8 17 8 32 0 15 10 52 21 82 21 55 35 121 45 215 3 28 10 54 15 59
5 6 9 16 9 23 0 7 11 36 24 65 14 29 27 69 30 88 14 93 35 180 50 210 9 18 16
41 16 50 0 9 3 20 7 24 14 13 43 122 43 160 0 44 8 73 56 197 21 53 34 105 34
132 0 25 5 49 10 52 6 3 10 24 10 46 0 25 13 68 34 112 33 71 66 208 66 277 0
20 15 65 35 105 19 39 35 80 35 92 0 24 19 71 35 89 12 14 784 8 829 -7z
m-7664 -16364 c273 -41 407 -136 475 -336 40 -116 50 -208 49 -449 0 -191 -4
-240 -22 -325 -75 -351 -298 -469 -857 -452 -466 14 -648 128 -717 453 -19 85
-22 132 -22 334 0 204 3 248 22 335 60 278 208 403 527 445 107 14 443 11 545
-5z m-7182 -167 l-3 -163 -477 -3 -478 -2 0 -145 0 -145 475 0 475 0 0 -165 0
-165 -475 0 -475 0 0 -290 0 -290 -205 0 -205 0 0 765 0 765 685 0 685 0 -2
-162z m1222 -96 c68 -141 194 -399 278 -572 85 -173 196 -402 248 -508 l93
-192 -210 2 -211 3 -67 145 -67 145 -409 0 -409 0 -67 -145 -68 -145 -216 -3
c-123 -1 -215 1 -213 6 1 5 169 350 372 766 l370 756 225 0 226 0 125 -258z
m2170 88 l0 -169 -477 -3 c-514 -3 -508 -3 -532 -55 -6 -13 -11 -51 -11 -83 0
-52 4 -64 29 -92 l29 -33 364 -5 c316 -5 369 -8 412 -24 147 -54 205 -139 227
-333 13 -117 -1 -265 -32 -349 -25 -65 -85 -137 -141 -165 -90 -46 -129 -49
-725 -49 l-563 0 0 170 0 170 483 1 c265 1 493 4 506 8 46 13 66 55 66 137 0
67 -2 76 -28 101 l-27 28 -358 6 c-385 6 -402 8 -497 64 -54 31 -96 86 -126
164 -26 68 -38 290 -20 385 22 118 78 204 162 247 92 46 112 48 702 48 l557 1
0 -170z m670 -125 l0 -295 360 0 360 0 0 295 0 295 205 0 205 0 0 -765 0 -765
-205 0 -205 0 0 300 0 300 -363 0 -363 0 4 -300 5 -300 -212 0 -211 0 0 765 0
765 210 0 210 0 0 -295z m1810 -470 l0 -765 -210 0 -210 0 0 765 0 765 210 0
210 0 0 -765z m2766 423 c131 -189 296 -426 366 -528 71 -102 133 -187 139
-188 6 -3 9 184 7 527 l-3 531 193 0 192 0 0 -765 0 -765 -252 0 -253 1 -365
517 -365 516 -3 -517 -2 -517 -189 0 c-163 0 -190 2 -195 16 -3 9 -6 353 -6
765 l0 749 249 0 249 0 238 -342z m3046 315 c258 -72 359 -227 389 -596 14
-166 1 -378 -31 -503 -54 -213 -172 -333 -375 -381 -59 -13 -156 -17 -612 -20
l-543 -5 0 767 0 766 548 -3 c521 -4 551 -5 624 -25z m1924 -710 c197 -405
364 -749 371 -765 l12 -28 -203 0 -203 0 -69 150 -69 150 -415 -3 -414 -2 -66
-145 -66 -145 -212 -3 -211 -2 103 212 c57 117 224 462 372 765 l269 553 221
0 220 0 360 -737z m1674 572 l0 -165 -270 0 -270 0 0 -600 0 -600 -200 0 -200
0 0 600 0 600 -270 0 -270 0 0 165 0 165 740 0 740 0 0 -165z m1346 -591 c203
-416 371 -760 372 -765 2 -5 -84 -9 -201 -9 l-204 0 -69 150 -69 150 -413 -3
-413 -2 -67 -145 -67 -145 -212 -3 -212 -2 123 252 c67 139 235 484 372 765
l249 513 221 0 221 0 369 -756z"/>
<path d="M13330 8037 c-84 -20 -137 -52 -170 -103 -46 -73 -55 -129 -55 -334
0 -207 10 -263 57 -334 56 -86 188 -123 393 -113 184 10 261 44 307 137 42 82
51 153 45 350 -5 210 -18 262 -80 324 -62 62 -121 78 -297 82 -95 2 -164 -1
-200 -9z"/>
<path d="M7358 7737 c-64 -138 -120 -260 -124 -269 -6 -17 10 -18 250 -18 141
0 256 2 256 3 0 12 -250 532 -257 534 -4 2 -61 -111 -125 -250z"/>
<path d="M17528 8033 c-2 -4 -2 -201 0 -437 l4 -428 286 4 c277 3 289 4 335
27 57 28 92 75 115 158 23 83 23 413 0 496 -24 84 -60 135 -116 160 -44 21
-65 22 -333 25 -158 2 -289 0 -291 -5z"/>
<path d="M19611 7958 c-10 -24 -67 -146 -125 -271 -58 -126 -106 -230 -106
-233 0 -2 117 -4 261 -4 243 0 261 1 255 17 -27 70 -251 533 -258 533 -5 0
-17 -19 -27 -42z"/>
<path d="M22607 7923 c-42 -93 -101 -220 -168 -362 -27 -57 -49 -106 -49 -107
0 -2 117 -4 260 -4 143 0 260 3 260 6 0 14 -253 544 -260 544 -4 0 -23 -35
-43 -77z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
